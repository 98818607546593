import React from "react"
import Layout from "../components/Layout"
import PrivacyPolicy from "../components/PrivacyPolicy"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import NavBar from "../components/NavBar"

export default function Privacy() {
  return (
    <Layout>
      <SEO
        title="Privacy Policy" />
      <NavBar />
      <PrivacyPolicy />
      <Footer />
    </Layout>
  )
}